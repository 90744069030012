<template>
  <div style="padding: 0px">
    <el-row>
      <el-col>
        <div class="add-tag-section">
          <span slot="label" class="has-text-grey" style="font-size: 14px; padding-right: 10px">
            Tags:
          </span>
          <!-- Tags  -->
          <el-tooltip
            v-for="tag in selectedIntent.tags"
            :key="tag"
            class="item"
            effect="dark"
            :content="tag"
            :disabled="tag.length < 22"
            placement="top"
          >
            <el-tag
              :disabled="!isAuthorisedForChange(selectedIntent.department)"
              type="primary"
              style="background-color: #409eff; color: #fff; margin-right: 10px !important"
              size="small"
              :closable="true"
              :disable-transitions="false"
              @close="deleteTag(selectedIntent, tag)"
            >
              {{ tag | trim(22) }}
            </el-tag>
          </el-tooltip>

          <el-input
            v-if="selectedIntent.tagInputVisible"
            ref="saveTagInput"
            v-model="selectedIntent.tagInputValue"
            type="text"
            class="input-new-tag"
            size="mini"
            v-focus
            @keyup.enter.native="newTagConfrim(selectedIntent)"
            @blur="newTagConfrim(selectedIntent)"
          />
          <el-button
            v-else
            :disabled="!isAuthorisedForChange(selectedIntent.department)"
            class="button-new-tag"
            size="mini"
            @click="showTagInput(selectedIntent)"
          >
            + New Tag
          </el-button>
        </div>
      </el-col>
    </el-row>

    <FaqAnswerDepartment :intent="selectedIntent" />

    <el-row>
      <el-row>
        <el-col :span="24">
          <EventNodeSelector
            :value="selectedIntentAnswer"
            @save-side-effect="$emit('save', { rowId: selectedIntent.id, action: 'MODIFY' })"
          />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-tooltip
            v-if="!isAuthorisedForChange(selectedIntent.department)"
            content="Not authorised!"
            placement="right"
          >
            <div>
              <el-button
                :disabled="!isAuthorisedForChange(selectedIntent.department)"
                type="primary"
                size="mini"
                icon="el-icon-check"
              >
                Save
              </el-button>
            </div>
          </el-tooltip>
          <el-button
            v-else
            type="primary"
            icon="el-icon-check"
            size="mini"
            @click="$emit('save', { rowId: selectedIntent.id, action: 'MODIFY' })"
          >
            Save
          </el-button>
        </el-col>
      </el-row>
    </el-row>
    <el-row v-if="this.$store.state.showAdvanced">
      <el-col>
        <JSONEditor v-model="clonedComputedSingleRowData" />
      </el-col>
    </el-row>

    <!-- Node Preview Section -->
    <el-row>
      <el-row>
        <el-col style="text-align: center">
          <el-card
            class="nodePreviewSection"
            width="100%"
            shadow="never"
            body-style="border: 1px 0px"
          >
            <span class="has-text-dark">Node Preview</span>
          </el-card>
        </el-col>
      </el-row>
      <el-col class="nodePreviewSection">
        <div v-if="selectedNodeData">
          <Bubble
            v-model="selectedNodeData"
            @save-side-effect="$emit('save', { rowId: selectedIntent.id, action: 'MODIFY' })"
          />
          <div class="edit-node-btn-section">
            <el-button class="edit-node-btn" @click="selectNode(selectedIntent.action)">
              Edit node In Editor
            </el-button>
          </div>
        </div>
        <div v-else class="no-preview-section">
          <span>
            This intent has no action.
            <br />Please select an action above.
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FaqAnswerDepartment from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/SingleFaqQuestionDetail/FaqAnswerDepartment.vue";
import EventNodeSelector from "@/components/EventNodeSelector/Index";

export default {
  data() {
    return {
      selectedIntent: {
        id: 1,
        tags: ["Customer Support", "Technical Issue", "Billing"],
        tagInputVisible: false,
        tagInputValue: "",
        department: "Support",
      },
      selectedIntentAnswer: "",
      clonedComputedSingleRowData: {},
      selectedNodeData: null,
    };
  },
  methods: {
    isAuthorisedForChange(department) {
      // Assuming some authorization logic based on department
      return department === "Support"; // Example: Only 'Support' department is authorized
    },
    deleteTag(intent, tag) {
      const index = intent.tags.indexOf(tag);
      if (index >= 0) {
        intent.tags.splice(index, 1);
      }
    },
    newTagConfrim(intent) {
      const value = intent.tagInputValue;
      if (value && !intent.tags.includes(value)) {
        intent.tags.push(value);
      }
      intent.tagInputValue = "";
      intent.tagInputVisible = false;
    },
    showTagInput(intent) {
      intent.tagInputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    selectNode(action) {
      // Custom logic to select node for editing
      console.log(`Selected node action: ${action}`);
    },
  },
  components: {
    FaqAnswerDepartment,
    EventNodeSelector,
  },
};
</script>

<style lang="scss" scoped></style>
