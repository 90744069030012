<template>
  <el-tabs type="border-card" value="description">
    <!-- Examples tab -->
    <el-tab-pane name="description" label="Description" :lazy="true">
      <el-input
        type="textarea"
        :autosize="{ minRows: 10, maxRows: 20 }"
        v-model="currentSelectIntent.description"
      />
    </el-tab-pane>

    <!-- Answer tab -->
    <el-tab-pane name="answer" label="Answer" :lazy="true">
      <SmartClassifierAnswer />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import SmartClassifierAnswer from "./SmartClassifierAnswer.vue";

export default {
  props: {
    currentSelectIntent: {
      type: Object,
      required: true,
    },
  },
  components: {
    SmartClassifierAnswer,
  },
};
</script>

<style lang="scss" scoped></style>
