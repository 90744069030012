<template>
  <div>
    <el-row :gutter="5" type="flex">
      <SearchBar placeholder="Search for an intent or example here..." />
      <el-select
        class="controll-margin"
        style="width: 100%; max-width: 300px"
        collapse-tags
        multiple
        filterable
        placeholder="Filter by tags"
      >
        <el-option v-for="tag in listTags" :key="tag" :label="tag" :value="tag" />
      </el-select>
      <el-tooltip
        content="Select a content node to trigger the fallback flow when no matching intents are found."
        placement="top"
      >
        <el-button type="primary"> Set fallback </el-button>
      </el-tooltip>
      <el-tooltip content="Import as CSV" placement="top">
        <el-button
          class="controll-margin"
          type="primary"
          size="small"
          @click="dialogUploadVisible = true"
        >
          <img class="importBtnIcon" src="img/icons/imp-exp/imp2.png" />
        </el-button>
      </el-tooltip>
      <el-tooltip content="Export as CSV" placement="top">
        <el-button class="exportAddNewBtn importExportBtn" plain>
          <img class="importBtnIcon" src="img/icons/imp-exp/exp2.png" />
        </el-button>
      </el-tooltip>
    </el-row>

    <el-row>
      <el-col :md="24" :lg="12" class="first-section-content">
        <SmartClassifierDataSetList
          :pagedDataset="pagedDataset"
          :currentSelectIntent="currentSelectIntent"
          @handleIntentEdit="handleIntentEdit"
        />
      </el-col>

      <el-col :md="24" :lg="12" class="detail-section-content">
        <DefaultPageWithIcon v-if="!currentSelectIntent" :visible-dataset="pagedDataset" />
        <SingleClassifierDetails v-else :currentSelectIntent="currentSelectIntent" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SearchBar from "@/components/Faq/searchBar.vue";
import SmartClassifierDataSetList from "./SmartClassifierDataSetList.vue";
import SingleClassifierDetails from "./SingleClassifierDetails.vue";
import DefaultPageWithIcon from "./DefaultPageWithIconClassifier.vue";

export default {
  components: {
    SearchBar,
    SmartClassifierDataSetList,
    SingleClassifierDetails,
    DefaultPageWithIcon,
  },
  data() {
    return {
      listTags: ["tag1", "tag2", "tag3"],
      currentSelectIntent: null,
      pagedDataset: [
        {
          id: 1,
          intentName: "BookFlight",
          department: "Travel",
          edit: false,
          examples: 5,
          enabled: true,
          description: "Book a flight",
        },
        {
          id: 2,
          intentName: "CancelReservation",
          department: "Customer Support",
          edit: false,
          examples: 3,
          enabled: false,
          description: "Cancel a reservation",
        },
        {
          id: 3,
          intentName: "OrderStatus",
          department: "Sales",
          edit: true,
          examples: 0,
          enabled: false,
          description: "Check order status",
        },
        {
          id: 4,
          intentName: "FindStore",
          department: "Retail",
          edit: false,
          examples: 2,
          enabled: true,
          description: "Find a store",
        },
        {
          id: 5,
          intentName: "MakeAppointment",
          department: "Healthcare",
          edit: true,
          examples: 4,
          enabled: true,
          description: "Make an appointment",
        },
      ],
    };
  },
  methods: {
    handleIntentEdit(row) {
      this.currentSelectIntent = row;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.controll-margin {
  margin-right: 8px;
}

.importBtnIcon {
  height: 18px;
  width: 18px;
}

.import_popup {
  padding: 20px;
}

.file-upload-inner-text {
  font-size: 20px;
  display: block;
  height: 50px;
  text-align: center;
  line-height: 160px;
  color: $color-dark;
}

.exportAddNewBtn {
  padding: 10px;
}
.importBtnIcon {
  height: 18px;
  width: 18px;
}

.first-section-content {
  height: 81vh;
  overflow-y: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
