import { render, staticRenderFns } from "./FAQ 2.0.vue?vue&type=template&id=9968f1f0&scoped=true&"
import script from "./FAQ 2.0.vue?vue&type=script&lang=js&"
export * from "./FAQ 2.0.vue?vue&type=script&lang=js&"
import style0 from "./FAQ 2.0.vue?vue&type=style&index=0&id=9968f1f0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9968f1f0",
  null
  
)

export default component.exports