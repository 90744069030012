<template>
  <div>
    <el-main>
      <el-row :gutter="10">
        <SmartClassifierNewIntent @handleBlur="handleBlur" @isDirty="isDirty" />
        <el-pagination
          background
          small
          layout="prev, pager, next"
          :pageSize="5"
          :total="50"
          style="display: inline-block"
        ></el-pagination>
        <div style="display: inline-block">
          <span style="font-size: 10px" class="has-text-grey">61 intens</span>
        </div>
      </el-row>
      <el-table
        ref="dataset"
        style="width: 100%"
        size="small"
        :data="pagedDataset"
        :row-key="(row, index) => index"
        @cell-click="handleCellClick"
      >
        <!-- Intent name -->
        <el-table-column
          width="200"
          sortable
          header-align="center"
          label="Intent Name"
          column-key="intentName"
        >
          <template slot-scope="scope">
            <!-- new question -->
            <div v-if="currentSelectIntent?.id !== scope.row.id" style="margin-left: 1em">
              {{ scope.row.intentName }}
            </div>
            <!-- v-else is an input with v-model is intent name -->
            <div v-else>
              <el-input
                v-model="scope.row.intentName"
                size="mini"
                placeholder="Intent Name"
                style="width: 100%"
                @blur="handleBlur"
              ></el-input>
            </div>
          </template>
        </el-table-column>

        <!-- Variation length -->
        <el-table-column
          sortable
          column-key="examples"
          header-align="center"
          label="Intent Description"
          width="400"
        >
          <template slot-scope="scope">
            <div class="single-line">{{ scope.row.description }}</div>
          </template>
        </el-table-column>

        <!-- Enable -->
        <el-table-column header-align="center" label="Enabled" width="80" align="center">
          <template slot-scope="props">
            <!-- For questions that can be enabled -->
            <el-switch v-model="props.row.enabled"></el-switch>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="48" align="center">
          <template>
            <div>
              <el-tooltip content="Delete this question" placement="right">
                <el-button size="mini" type="danger" icon="el-icon-delete" circle />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </div>
</template>

<script>
import SmartClassifierNewIntent from "./SmartClassifierNewIntent.vue";

export default {
  components: {
    SmartClassifierNewIntent,
  },
  props: {
    pagedDataset: {
      type: Array,
      required: false,
    },
    currentSelectIntent: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      expandedRows: [],
      defaultIntentNameValue: "Unnamed Intent",
      badgeForQustions: {
        1: false,
        2: true,
        3: false,
        4: false,
        5: true,
      },
    };
  },
  methods: {
    handleCellClick(row, column, cell, event) {
      if (column.label === "Intent Name" || column.label === "Intent Description") {
        this.$emit("handleIntentEdit", row);
      }
    },
    handleBlur() {
      this.$emit("handleIntentEdit", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.single-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 50%;
}
</style>
